<template>
  <div class="itembox" :class="{ itemEnBox: LOCALE == 'en' }">
    <div
      class="companyName textOverflow"
      :title="
        membermsg.company_name_en
          | priorFormat(membermsg.company_name_zh, this.LOCALE)
      "
    >
      <a
        class="name"
        :href="
          '/companyDetail?parameter=' +
          _encode({
            company_id: membermsg.company_id,
          })
        "
        target="_blank"
      >
        {{
          membermsg.company_name_en
            | priorFormat(membermsg.company_name_zh, this.LOCALE)
        }}
      </a>
    </div>
    <div class="header">
      <p>
        <img
          @click="detail_dialog(membermsg)"
          class="cursor"
          style="width: 72px; height: 72px; border-radius: 36px"
          :src="membermsg.avatar ? membermsg.avatar : default_header_img"
          :alt="$t('siffa')"
        />
      </p>
      <div>
        <div style="display: flex; align-items: center">
          <h1
            class="textOverflow"
            :title="membermsg.name_en | priorFormat(membermsg.name_zh, LOCALE)"
          >
            {{ membermsg.name_en | priorFormat(membermsg.name_zh, LOCALE) }}
          </h1>
          <img
            v-if="membermsg.is_follow == 1"
            :alt="$t('siffa')"
            src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaStatic/img/shoucang.png"
          />
        </div>

        <p class="textOverflow">
          {{
            membermsg.job_title_en | priorFormat(membermsg.job_title_zh, LOCALE)
          }}
        </p>
      </div>
    </div>
    <div class="content clearfix">
      <div
        class="fl textOverflow"
        :title="
          membermsg.company_country_id
            | countryCityFormat(
              membermsg.company_region_en,
              membermsg.company_region_zh,
              LOCALE
            )
        "
      >
        <i class="el-icon-location"></i>
        <span>{{
          membermsg.company_country_id
            | countryCityFormat(
              membermsg.company_region_en,
              membermsg.company_region_zh,
              LOCALE
            )
        }}</span>
      </div>
      <div class="frEn" v-if="LOCALE == 'en'"></div>
      <div class="fr">
        <el-button type="default" size="small" @click="handclick">{{
          $t("reserveMeeting")
        }}</el-button>
        <el-button
          v-if="USER_INFO.aila_no"
          :disabled="membermsg.user_id == USER_INFO.id"
          type="primary"
          size="small"
          @click="detail_dialog(membermsg)"
          :loading="collectLoading"
          >{{ $t("cardCollect") }}</el-button
        >
        <el-button
          v-else
          type="primary"
          size="small"
          @click="detail_dialog(membermsg)"
          >{{ $t("cardExchange") }}</el-button
        >
      </div>
    </div>
    <orderFreeDialog
      :orderVisible="orderVisible"
      @dataChange="_dataChange"
      @orderVisibleShow="_orderVisibleShow"
    ></orderFreeDialog>
  </div>
</template>
<script>
import orderFreeDialog from "@/baseComponents/orderFreeDialog";
import cardDialog from "@/baseComponents/card/cardIsDialog";

import { validateEmail } from "~/baseUtils/validate";
export default {
  props: {
    membermsg: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  components: {
    orderFreeDialog,
    cardDialog,
  },
  data() {
    return {
      default_header_img:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/headportrait.png",
      company_img:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/company.png",
      email_img:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/email.png",
      tel_img:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/tel.png",
      watch:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/watch.png",
      orderVisible: false,
      collectLoading: false,
    };
  },
  methods: {
    validateEmail,
    handclick() {
      if (!this.IS_LOGIN) {
        this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
      } else if (
        this.USER_INFO.company_bind_status == 0 ||
        !this.USER_INFO.company_id
      ) {
        //没有绑定公司
        this.$message.warning(this.$t("company_information"));
      } else {
        this.orderVisible = true;
      }
    },
    detail_dialog(row) {
      this.$GLOBALEVENT.$emit("OPENISUSERCARD", { show: true, row });
    },
    to_companyDetai() {
      let routeUrl = this.$router.resolve({
        path: "/companyDetail",
        query: {
          parameter: this._encode({
            company_id: this.membermsg.company_id,
          }),
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    async _dataChange(data) {
      let { start_time, end_time, day, send_postscript } = data;
      let params = {
        send_user_id: this.USER_ID, //
        send_company_id: this.USER_INFO.company_id, //
        in_user_id: this.membermsg.user_id,
        in_company_id: this.membermsg.company_id,
        daily_source: 2,
        related_id: this.USER_INFO.company_id, //
        start_time,
        end_time,
        day,
        send_postscript,
        source: this.PJSource,
      };

      let res = await this.$store.dispatch("API_company/companyInvite", params);

      this.orderVisible = false;
      if (res.success) {
        this.$message.success(this.$t("sendSuccess"));
      } else if (res.errorcode == 1000) {
        this.$message.warning(this.$t("repeatTheBooking"));
      } else if (res.errorcode == 1017) {
        this.$message.warning(this.$t("companyCatalog1v1Error2"));
      } else if (res.errorcode == 1018) {
        this.$message.warning(this.$t("companyCatalog1v1Error3"));
      } else {
      }
    },
    _orderVisibleShow(data) {
      this.orderVisible = false;
    },
    to_login() {
      this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
    },
  },
};
</script>
<style scoped lang="less">
.itembox {
  width: 390px;
  height: 188px;
  background: #fff;
  border-radius: 2px;
  border: 1px solid #e9e9e9;
  margin-right: 15px;
  margin-bottom: 15px;
  padding: 16px;
  background: #ffffff;
  float: left;
  .companyName {
    cursor: pointer;
    height: 40px;
    font-size: 14px;
    font-weight: 400;
    a {
      color: #909399;
    }
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .header {
    display: flex;
    padding: 0px;
    p {
      width: 72px;
      height: 72px;
      margin-right: 19px;
    }
    div {
      width: 270px;
      h1 {
        max-width: 200px;
        overflow: hidden;
        height: 28px;
        font-size: 18px;
        font-weight: 600;
        color: #666;
        line-height: 28px;
        margin-top: 12px;
      }
      img {
        width: 18px;
        height: 18px;
        margin-top: 12px;
        margin-left: 10px;
      }
      p {
        margin-top: 5px;
        height: 22px;
        font-size: 16px;
        font-weight: 400;
        color: #7d8695;
        line-height: 22px;
        width: 100%;
        overflow: hidden;
      }
    }
  }
  .content {
    margin-top: 10px;
    .fl {
      max-width: 180px;
      line-height: 32px;
      i {
        color: #999;
        font-weight: 700;
        font-size: 18px;
        position: relative;
        top: 2px;
      }
      span {
        font-size: 14px;
        font-weight: 400;
        color: #999;
        padding-left: 2px;
      }
    }
    .frEn {
      width: 100%;
      height: 32px;
    }
  }
  .btn {
    padding: 24px;
    .btn_button {
      cursor: pointer;
      width: 115px;
      height: 30px;
      background: linear-gradient(90deg, #ff9966 0%, #ff5e62 100%);
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-right: 6px;
      }
      span {
        display: inline-block;
        width: 56px;
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
      }
    }
  }
}
.itemEnBox {
  height: 220px;
}
</style>