var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"itembox",class:{ itemEnBox: _vm.LOCALE == 'en' }},[_c('div',{staticClass:"companyName textOverflow",attrs:{"title":_vm._f("priorFormat")(_vm.membermsg.company_name_en,_vm.membermsg.company_name_zh, this.LOCALE)}},[_c('a',{staticClass:"name",attrs:{"href":'/companyDetail?parameter=' +
        _vm._encode({
          company_id: _vm.membermsg.company_id,
        }),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm._f("priorFormat")(_vm.membermsg.company_name_en,_vm.membermsg.company_name_zh, this.LOCALE))+" ")])]),_c('div',{staticClass:"header"},[_c('p',[_c('img',{staticClass:"cursor",staticStyle:{"width":"72px","height":"72px","border-radius":"36px"},attrs:{"src":_vm.membermsg.avatar ? _vm.membermsg.avatar : _vm.default_header_img,"alt":_vm.$t('siffa')},on:{"click":function($event){return _vm.detail_dialog(_vm.membermsg)}}})]),_c('div',[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('h1',{staticClass:"textOverflow",attrs:{"title":_vm._f("priorFormat")(_vm.membermsg.name_en,_vm.membermsg.name_zh, _vm.LOCALE)}},[_vm._v(" "+_vm._s(_vm._f("priorFormat")(_vm.membermsg.name_en,_vm.membermsg.name_zh, _vm.LOCALE))+" ")]),(_vm.membermsg.is_follow == 1)?_c('img',{attrs:{"alt":_vm.$t('siffa'),"src":"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaStatic/img/shoucang.png"}}):_vm._e()]),_c('p',{staticClass:"textOverflow"},[_vm._v(" "+_vm._s(_vm._f("priorFormat")(_vm.membermsg.job_title_en,_vm.membermsg.job_title_zh, _vm.LOCALE))+" ")])])]),_c('div',{staticClass:"content clearfix"},[_c('div',{staticClass:"fl textOverflow",attrs:{"title":_vm._f("countryCityFormat")(_vm.membermsg.company_country_id,
            _vm.membermsg.company_region_en,
            _vm.membermsg.company_region_zh,
            _vm.LOCALE
          )}},[_c('i',{staticClass:"el-icon-location"}),_c('span',[_vm._v(_vm._s(_vm._f("countryCityFormat")(_vm.membermsg.company_country_id, _vm.membermsg.company_region_en, _vm.membermsg.company_region_zh, _vm.LOCALE )))])]),(_vm.LOCALE == 'en')?_c('div',{staticClass:"frEn"}):_vm._e(),_c('div',{staticClass:"fr"},[_c('el-button',{attrs:{"type":"default","size":"small"},on:{"click":_vm.handclick}},[_vm._v(_vm._s(_vm.$t("reserveMeeting")))]),(_vm.USER_INFO.aila_no)?_c('el-button',{attrs:{"disabled":_vm.membermsg.user_id == _vm.USER_INFO.id,"type":"primary","size":"small","loading":_vm.collectLoading},on:{"click":function($event){return _vm.detail_dialog(_vm.membermsg)}}},[_vm._v(_vm._s(_vm.$t("cardCollect")))]):_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.detail_dialog(_vm.membermsg)}}},[_vm._v(_vm._s(_vm.$t("cardExchange")))])],1)]),_c('orderFreeDialog',{attrs:{"orderVisible":_vm.orderVisible},on:{"dataChange":_vm._dataChange,"orderVisibleShow":_vm._orderVisibleShow}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }