<template>
  <div>
    <carouselBanner paddingTop="14.6%" :bannerType="4" />

    <div class="content_area">
      <searchCompany
        class="search-wrap"
        v-model="queryParams.name"
        @search="_search"
        :placeTitle="$t('PleaseCompanyOruser')"
      ></searchCompany>

      <filterSearch
        @handleSearch="_handleSearch"
        style="margin-bottom: 24px"
      ></filterSearch>
      <div class="wrap">
        <div v-if="MemberList.length > 0" class="clearfix">
          <memberItem
            v-for="(item, i) in MemberList"
            :key="i"
            :membermsg="item"
            :class="{ is_right: (i + 1) % 3 == 0 }"
            class="infinite-list-item"
          ></memberItem>
        </div>
        <isAllDataTip
          v-if="total != 0 && total == MemberList.length"
        ></isAllDataTip>
        <isLoadingTip v-if="loading"></isLoadingTip>
        <noDataImg v-if="MemberList.length == 0 && !loading" />
        <div class="clearfix" v-show="MemberList.length">
          <el-pagination
            class="fr"
            style="margin-top: 10px"
            @current-change="handleChange"
            :current-page="currentPage"
            :total="total"
            background
            :page-size="12"
            layout="prev, pager, next"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <cardDialog
      @excardChange="excardChange"
      @excardSucceed="excardSucceed"
    ></cardDialog>
  </div>
</template>

<script>
import carouselBanner from "@/baseComponents/carouselBanner";
import searchCompany from "@/projects/henanSociety/components/company/unit/searchCompany";
import filterSearch from "~hns/components/common/filterSearch";
import memberItem from "~hns/components/company/member/memberItem";
import noDataImg from "@/baseComponents/noDataImg";
import isLoadingTip from "@/baseComponents/isLoadingTip";
import isAllDataTip from "@/baseComponents/isAllDataTip";
import cardDialog from "@/baseComponents/card/cardIsDialog";

export default {
 metaInfo() {
    return {
      title: this.$t("concatList"),
    };
  },
  components: {
    carouselBanner,
    searchCompany,
    filterSearch,
    memberItem,
    isLoadingTip,
    isAllDataTip,
    cardDialog,
  },
  data() {
    return {
      currentPage: 1,
      total: 0,
      MemberList: [],
      loading: false,
      queryParams: {
        name: "", //公司名字
        city_id: "", //城市id
        company_credit: "", /// 信用评级：1-AAA，2-AA，3-A
        genre_ids: "", //
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    handleChange(index) {
      this.currentPage = index;
      this.getList();
    },
    async getList(type) {
      //type 1 条件查询  type 2 触底出发
      // 防止多次出发 请求事件
      this.loading = true;
      let params = {};
      for (var key in this.queryParams) {
        params[key] = this.queryParams[key];
      }
      params.start = this.currentPage - 1;
      params.limit = 12;
      params.certificate = 20;
      params.user_id = this.USER_ID;
      params.company_source = this.PJSource;
      let res = await this.$store.dispatch("API_company/getMemberList", params);
      if (res.success) {
        this.MemberList = res.data;
        this.total = res.total;
        this.loading = false;
        document.body.scrollIntoView();
        // window.addEventListener("scroll", this.scrollEvent, false);
        // this.loading = false;
        // this.total = res.total;
        // if (type == 2) {
        //   this.MemberList.push(...res.data);
        // } else if (type == 1) {
        //   this.MemberList = res.data;
        // }
      }
    },
    scrollEvent() {
      if (
        document.documentElement.scrollTop +
          document.documentElement.clientHeight >=
        document.body.scrollHeight - 320
      ) {
        if (this.total != this.MemberList.length) {
          this.currentPage++;
          this.getList(2);
          // 防止多次出发 请求事件
          window.addEventListener("scroll", this.scrollEvent, false);
        }
      }
    },
    //
    _handleSearch(query) {
      this.queryParams.city_id = query.city; //城市id
      this.queryParams.company_credit = query.credit; // 信用评级：1-AAA，2-AA，3-A
      this.queryParams.genre_ids = query.unitType;
      this.currentPage = 1;
      this.getList(1);
    },
    _search() {
      this.currentPage = 1;
      this.getList(1);
    },
    excardChange(val, type) {},
    excardSucceed(val) {
      this.MemberList.find((item) => val.user_id == item.user_id).is_follow =
        val.is_follow;
    },
  },
};
</script>
<style scoped lang="less">
.is_right {
  margin-right: 0;
}
.search-wrap {
  text-align: right;
  margin-bottom: 16px;
}
.member_box {
  display: flex;
  flex-wrap: wrap;
  background-color: "#fafafa";
}
.wrap {
  min-height: 300px;
  padding-bottom: 16px;
  position: relative;
}
/deep/ .el-button--danger {
  border-color: #1D2388;
  background-color: #1D2388;
}
/deep/ .el-button--danger:hover
 {
  border-color: #4A4FA0;
  background-color: #4A4FA0;
}
</style>